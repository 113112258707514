import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';

//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralEventAnalyticPage,
  GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Event
  EventPostPage,
  EventNewPostPage,
  EventAccountPage,
  // Dashboard: Event
  AttendeesCreatePage,
  AttendeesListPage,

  // Dashboard: BreakOut Rooms
  BreakOutCreatePage,
  BreakOutListPage,


  // Dashboard: FileManager
  FileManagerPage,
  //
  BlankPage,
  //
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  OrgSelectPage,
  EventUserListPage,
  EventUserCreatePage,
  SuperAdminEventListPage,
  SuperAdminAllOrgsListPage,
  SuperAdminOrgCreatePage,
  SessionCreatePage,
  SessionListPage,
  EventPublicSummary,
  EventVirtualEventPage,
  EventFeedbackListPage,
  EventAttendeeRegistrationForm,
} from './elements';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      // path: '/',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'event_analytics', element: <GeneralEventAnalyticPage /> },
        {
          path: 'event',
          children: [
            { element: <Navigate to="/dashboard/event/all" replace />, index: true },
            { path: 'all', element: <GeneralAnalyticsPage /> },
            { path: 'event/:title', element: <EventPostPage /> },
            { path: 'new', element: <EventNewPostPage /> },
            { path: 'setting', element: <EventAccountPage /> },
          ],
        },
        {
          path: 'event_user',
          children: [
            { element: <Navigate to="/dashboard/event_user/all" replace />, index: true },
            { path: 'all', element: <EventUserListPage /> },
            { path: 'new', element: <EventUserCreatePage /> },
            { path: ':id/edit', element: <EventUserCreatePage /> },
          ],
        },

        {
          path: 'super',
          children: [
            { element: <Navigate to="/dashboard/super/all_events" replace />, index: true },

            { path: 'all_events', element: <SuperAdminEventListPage /> },
            { path: 'all_orgs', element: <SuperAdminAllOrgsListPage /> },
            { path: 'add_org', element: <SuperAdminOrgCreatePage /> },
            { path: ':id/edit', element: <SuperAdminOrgCreatePage /> },
            

          ],
        },

        {
          path: 'attendees',
          children: [
            { element: <Navigate to="/dashboard/attendees/all" replace />, index: true },
            { path: 'all', element: <AttendeesListPage /> },
            { path: 'new', element: <AttendeesCreatePage /> },
            { path: ':id/edit', element: <AttendeesCreatePage /> },
          ],
        },
        {
          path: 'breakout',
          children: [
            { element: <Navigate to="/dashboard/breakout/all" replace />, index: true },
            { path: 'all', element: <BreakOutListPage /> },
            { path: 'new', element: <BreakOutCreatePage /> },
            { path: ':id/edit', element: <BreakOutCreatePage /> },
          ],
        },
        {
          path: 'event_feedback',
          children: [
            { element: <Navigate to="/dashboard/event_feedback/all" replace />, index: true },
            { path: 'all', element: <EventFeedbackListPage /> },
          ],
        },
        {
          path: 'breakout_session',
          children: [
            { element: <Navigate to="/dashboard/breakout_session/:room_id/all" replace />, index: true },
            { path: ':room_id/all', element: <SessionListPage /> },
            { path: ':room_id/new', element: <SessionCreatePage /> },
            { path: ':id/:room_id/edit', element: <SessionCreatePage /> },
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <SimpleLayout />,
      children: [
        { path: 'org-select', element: <OrgSelectPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: ':event/summary/public', element: <EventPublicSummary /> },
        { path: ':event/meet/:attendee_id', element: <EventVirtualEventPage /> },
        { path: ':event/registration', element: <EventAttendeeRegistrationForm /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '/', element: <Navigate to="/dashboard" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
