import { baseGet } from "./apiClient";

export const isAdmin = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return String(user?.role?.name).toLowerCase().includes('coordinator');
}

export const isNoAdmin = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return String(user?.role?.name).toLowerCase().includes('clerk') || String(user?.role?.name).toLowerCase().includes('usher');
}

export const isSuperAdmin = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return String(user?.role?.name).toLowerCase().includes('administrator');
}



export const isEventActive = () => {
  const event = JSON.parse(localStorage.getItem('current_event'));

  if (!event || !event.start_date || !event.end_date) {
    return false;
  }

  const eventStartDate = new Date(event.start_date);
  const eventEndDate = new Date(event.end_date);
  const today = new Date();

  // Compare only the year, month, and day for start date and end date
  const isAfterStartDate = today >= eventStartDate;
  const isBeforeEndDate = today < eventEndDate;

  return isAfterStartDate && isBeforeEndDate;
};

export const isEventPast = () => {
  const event = JSON.parse(localStorage.getItem('current_event'));

  if (!event || !event.end_date) {
    return false;
  }

  const eventEndDate = new Date(event.end_date);
  const today = new Date();

  // Compare only the year, month, and day
  return today > eventEndDate;
};

export const isThreeDaysBeforeEventStart = () => {
  const event = JSON.parse(localStorage.getItem('current_event'));

  if (!event || !event.start_date) {
    return false;
  }
  if (isEventActive()) {
    return false
  }

  const eventStartDate = new Date(event.start_date);
  // const today = new Date();
  // const threeDaysBefore = new Date();
  // threeDaysBefore.setDate(eventStartDate.getDate() - 3);
  // Get today's date
  const today = new Date();

  // Calculate three days before today
  const threeDaysBefore = new Date(eventStartDate);
  threeDaysBefore.setDate(eventStartDate.getDate() - 3);

  // Check if today is less than threeDaysBefore

  // console.log("Today: ", today);
  // console.log("Three Days Before: ", threeDaysBefore);
  // console.log("Is Today Before Three Days Before: ", isTodayBeforeThreeDaysBefore);



  // console.log(threeDaysBefore)
  // console.log(today)
  // console.log(eventStartDate)
  return today > threeDaysBefore;
};


export const isOnSitePrinting = () => {
  const event = JSON.parse(localStorage.getItem('current_event'));
  return event.is_onsite_printing && isEventActive();
}

export const updateLocalStorage = async (event_id) => {
  try {
    // Fetch the new value from the database
    // const response = await fetch(`your_database_endpoint/${event_id}`);
    const response = await baseGet(`main/v1/event/by_guid/${event_id}/`);
    console.log(response)
    // const newData = await response.json();

    console.log("here")
    console.log(response)

    // Set the new value in localStorage
    localStorage.setItem('current_event', JSON.stringify(response));


  } catch (error) {
    console.error('Error updating localStorage:', error);
  }
};

export const shortenUrl = async (long_url) => {

  let short_link = "";
  await fetch("https://api.tinyurl.com/create", {
    method: "POST",
    mode: "cors",
    headers: {
      // Authorization: `Bearer 7b2d9df389eabce12de4005ddf0b0af0f6459fc2`,
      Authorization: `Bearer QoNCKork7dQkP5WyfrvOb8t0LraRBzMc9niOnlfv6YSY92L7Yg72MeJTGTo1`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      url: long_url,
      domain: "tinyurl.com",
      // group_guid: `BnasguyLaOY`,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      short_link = data?.data.tiny_url;

    });

  return short_link;
};