import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL

export const GeneralAnalyticsPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAnalyticsPage'))
);

export const GeneralEventAnalyticPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralEventAnalyticsPage'))
);



// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));

// DASHBOARD: EVENT
export const EventPostsPage = Loadable(lazy(() => import('../pages/dashboard/EventPostsPage')));
export const EventPostPage = Loadable(lazy(() => import('../pages/dashboard/EventPostPage')));
export const EventNewPostPage = Loadable(lazy(() => import('../pages/dashboard/EventNewPostPage')));
export const EventAccountPage = Loadable(lazy(() => import('../pages/dashboard/EventAccountPage')));

// DASHBOARD: EVENT USERS
export const EventUserListPage = Loadable(lazy(() => import('../pages/dashboard/EventUserListPage')));
export const EventUserCreatePage = Loadable(lazy(() => import('../pages/dashboard/EventUserCreatePage')));


// DASHBOARD: SUPER ADMIN EVENT 
export const SuperAdminEventListPage = Loadable(lazy(() => import('../pages/dashboard/SuperAdminEventListPage')));
export const SuperAdminAllOrgsListPage = Loadable(lazy(() => import('../pages/dashboard/SuperAdminAllOrgsListPage')));
export const SuperAdminOrgCreatePage = Loadable(lazy(() => import('../pages/dashboard/SuperAdminOrgCreatePage')));

// DASHBOARD: ATTENDEES
export const AttendeesListPage = Loadable(lazy(() => import('../pages/dashboard/AttendeesListPage')));
export const AttendeesCreatePage = Loadable(lazy(() => import('../pages/dashboard/AttendeesCreatePage')));


// DASHBOARD: BreakOut Rooms
export const BreakOutListPage = Loadable(lazy(() => import('../pages/dashboard/BreakOutListPage')));
export const BreakOutCreatePage = Loadable(lazy(() => import('../pages/dashboard/BreakOutCreatePage')));

export const SessionListPage = Loadable(lazy(() => import('../pages/dashboard/SessionListPage')));
export const SessionCreatePage = Loadable(lazy(() => import('../pages/dashboard/SessionCreatePage')));


export const EventFeedbackListPage = Loadable(lazy(() => import('../pages/dashboard/EventFeedbackListPage')));

// DASHBOARD: FILE MANAGER
export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));



// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const EventPublicSummary = Loadable(lazy(() => import('../pages/EventPublicSummary')));
export const EventVirtualEventPage = Loadable(lazy(() => import('../pages/EventVirtualEventPage')));
export const EventAttendeeRegistrationForm = Loadable(lazy(() => import('../pages/EventAttendeeRegistrationForm')));
export const OrgSelectPage = Loadable(lazy(() => import('../pages/OrgSelectPage')));