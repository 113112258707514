// import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography } from '@mui/material';
import Image from '../../components/image';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// routes

// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
// import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src="/assets/background/Logo2.png"
          sx={{ maxWidth: 160, marginTop: '18px', position: 'absolute', right: 0 }}
        />
        <Typography variant="h4">Sign in</Typography>

        <br />

        {/* <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src='/assets/background/Logo2.png'
            sx={{ width: 102, height: 102, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
      </Stack>

      <AuthLoginForm />

      {/* <AuthWithSocial /> */}
    </LoginLayout>
  );
}
